import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import tingeeStoreModule from '../tingeeStoreModule';
import md5 from 'md5';

export default function useTingeeOcbModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'tingee';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tingeeStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };
  const step = ref(1);
  const isCoporation = ref(false);
  const bank = computed(() => itemLocal.value.bank);


  const resetModal = () => {
    resetItemLocal();
    itemLocal.value.accountName = '';
    itemLocal.value.mobile = '';
    itemLocal.value.email = '';
    itemLocal.value.accountNumber = '';
    itemLocal.value.merchantName = '';
    itemLocal.value.merchantAddress = '';
    itemLocal.value.identity = '';
    itemLocal.value.subId = '';
    itemLocal.value.confirmId = '';
    itemLocal.value.bank = null;
    itemLocal.value.agreeTerm = 0
    step.value = 1;
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }
    isSubmitting.value = true;
    let data = {
      accountName: itemLocal.value.accountName,
      mobile: itemLocal.value.mobile,
      email: itemLocal.value.email,
      accountNumber: itemLocal.value.accountNumber,
      merchantName: itemLocal.value.merchantName,
      merchantAddress: itemLocal.value.merchantName,
      identity: itemLocal.value.identity,
      bankId: itemLocal.value.bank ? itemLocal.value.bank.id : null,
      accountType: isCoporation.value === true ? 'business-account' : 'personal-account',
    };
    if (isCoporation.value === true) {
      data.subId = itemLocal.value.subId;
    }
    if (step.value === 1) {
      store
        .dispatch('tingee/connectBankAccount', data)
        .then(response => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
          itemLocal.value.confirmId = response.data.confirmId;
          step.value = 2;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      data = {
        ...data,
        otpNumber: itemLocal.value.otpCode,
        confirmId: itemLocal.value.confirmId,
        password: md5(itemLocal.value.password),
      };
      store
        .dispatch('tingee/verifyBankAccount', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const onChangeCoporation = newVal => {
    isCoporation.value = newVal;
  };

  watch(bank, newBank => {
    if (newBank && newBank.id === 3 && isCoporation.value) {
      isCoporation.value = false;
    }
  });

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    isCoporation,
    onChangeCoporation,
    resetItemLocal,
    resetModal,
    onSubmit,
    step,
  };
}
