import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTingees(ctx, params) {
      return useJwt.getTingeeAccounts(params).then(response => response);
    },
    createTingee(ctx, data) {
      return useJwt.createTingee(data).then(response => response);
    },
    updateTingee(ctx, data) {
      return useJwt.updateTingee(data).then(response => response);
    },
    getTingeeDetail(ctx, id) {
      return useJwt.getTingeeDetail(id).then(response => response);
    },
    deleteTingees(ctx, data) {
      return useJwt.deleteTingees(data).then(response => response);
    },
    tingeeVerifyOCBInfo(ctx, data) {
      return useJwt.tingeeVerifyOCBInfo(data).then(response => response);
    },
    tingeeVerifyOCBInfoWithOTP(ctx, data) {
      return useJwt.tingeeVerifyOCBInfoWithOtp(data).then(response => response);
    },
    tingeeCreateVAAccount(ctx, data) {
      return useJwt.tingeeCreateVAAccount(data).then(response => response);
    },
    connectBankAccount(ctx, data) {
      return useJwt.connectBankAccount(data).then(response => response);
    },
    verifyBankAccount(ctx, data) {
      return useJwt.verifyBankAccount(data).then(response => response);
    },
    applyOneTimeQrcode(ctx, tingeeId) {
      return useJwt.applyOneTimeQrcode(tingeeId).then(response => response);
    }
  },
};
