import {
  ref, watch, onUnmounted,
} from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';
import tingeeStoreModule from '../tingeeStoreModule';

export default function useTingeeList() {
  const BED_STORE_MODULE_NAME = 'tingee';

  // Register module
  if (!store.hasModule(BED_STORE_MODULE_NAME)) {
    store.registerModule(BED_STORE_MODULE_NAME, tingeeStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(BED_STORE_MODULE_NAME)) {
      store.unregisterModule(BED_STORE_MODULE_NAME);
    }
  });

  // Use toast
  const toastification = toast();

  const blankItem = {
    id: 0,
    name: '',
    phone: '',
    note: '',
    default: false,
    apartments: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Chủ tài khoản',
      field: 'accountName',
    },
    {
      label: 'Số tài khoản',
      field: 'accountNumber',
    },
    {
      label: 'Tài khoản định danh',
      field: 'vaAccountNumber',
    },
    {
      label: 'Ngân hàng',
      field: 'bank.shortName',
    },
    {
      label: 'Sử dụng QRCode một lần?',
      field: 'oneTimeQrcode',
    },

  ];
  const rows = ref([]);
  // filter
  const active = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });
  const analytic = ref(null);

  //   API Call
  const fetchTingees = () => {
    isLoading.value = true;
    store
      .dispatch('tingee/fetchTingees', serverParams.value)
      .then(response => {
        totalRecords.value = response.data.length;
        rows.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        isLoading.value = false;
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchTingees();
  };

  const deleteTingees = hotlines => {
    store
      .dispatch('tingee/deleteTingees', {
        ids: hotlines.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const setDeleteEnable = accounts => {
    // Chi khi tat ca hoa don chua duyet va chua thanh toan moi xoa duoc, con lai la khong hien thi nut xoa
    if (accounts.length === 0) {
      return false;
    }

    if (accounts.filter(obj => obj.isCash === true).length > 0) {
      return false;
    }
    return true;
  };

  const handleChangeOneTimeQRcode = tingeeItem => {
    store
      .dispatch('tingee/applyOneTimeQrcode', tingeeItem.id)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Đã cập nhật thành công');
      })
      .catch(error => {
        // fetchData();
        toastification.showToastError(error);
      });
  }

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onViewDetailItem = val => {
    item.value = val;
  };
  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(active, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.active = val.value;
    } else {
      delete filter.active;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });

  return {
    analytic,
    item,
    columns,
    rows,
    active,
    apartment,
    room,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    fetchTingees,
    deleteTingees,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    setDeleteEnable,
    handleChangeOneTimeQRcode
  };
}
